import { fetchGet, fetchPost } from '../index';
import {
    AGManagementQueryParams,
    TAManagementQueryParams,
} from '@/views/Checking/CheckingManagement/type';
//* 获取bu接口
export const getBuData = (): Promise<any> => {
    return fetchGet('');
};

//* AG table数据查询接口
export const getAGManagementData = (
    params: AGManagementQueryParams
): Promise<any> => {
    return fetchPost('/claimapi/claimChecking/agManagement/search', params);
};

//* TA table数据查询接口
export const getTAManagementData = (
    params: TAManagementQueryParams
): Promise<any> => {
    return fetchPost('/claimapi/claimChecking/taManagement/signTAList', params);
};

//* TDTA table数据查询接口
export const getTDTAManagementData = (
    params: TAManagementQueryParams
): Promise<any> => {
    return fetchPost('/claimapi/tdta/search', params);
};

//* 车辆信息级联查询
export const searchVehicle = (params: any): Promise<any> => {
    return fetchPost('/claimapi/tdta/search/vehicle', params);
};

//*  findBrandByBu
export const getBrand = (params: any): Promise<any> => {
    return fetchGet('/vapi/noAuth/findBrandByBu', params);
};

//* 根据 Program Code 模糊查询 Program Code 列表
export const getProgramCodeList = (params: any): Promise<any> => {
    return fetchGet('/pgapi/calc/claim/get_program_code_list', { params });
};

//* 根据BU、Program Code 模糊查询 Program Code 列表
export const findProgramCodeList = (buId: string, code: string): Promise<any> => {
    return fetchGet('/pgapi/calc/claim/find_program_code_list', {
      params: {
        buId,
        code
      }
    });
}

//* 根据program code 查询 taname
export const getProgramTANameList = (params: any): Promise<any> => {
    return fetchGet('/pgapi/calc/claim/get_program_taname_list', { params });
};

//* 获取用户信息的接口
export const getUser = (): Promise<any> => {
    return fetchGet('/usapi/users/currentuser');
};
