
import { defineComponent, onMounted, ref, reactive, nextTick, h, computed, watch } from 'vue'
import { keyModelsList, keyModelsCreate, keyModelsDelete, keyModelsEdit } from '@/API/localRegistration'
import { searchVehicle } from '@/API/checking/checkingManagement'
import Title from '../components/title.vue'
export default defineComponent({
  components: {
    Title: Title
  },
  setup() {
    const allBrandOptions = ref<string []>([])
    const allTypeClassOptions = ref<string []>([])
    const allpowerTrainOptions = ref<string []>([])
    const allModelOptions = ref<string []>([])
    const list = ref<any []>([])
    const handleAdd = ()=> {
      list.value.push({
        brand: '',
        typeClass: '',
        powerTrain: '',
        brandOptions: allBrandOptions.value || ['MBPC', 'AMG', 'VAN'],
        typeClassOptions: allTypeClassOptions.value,
        powerTrainOptions: allpowerTrainOptions.value,
        modelOptions: allModelOptions.value
      })
    }
    const handleRemove = (record: any, index: number) => {
      if (record.id) {
        keyModelsDelete(record.id).then(() => {
          list.value.splice(index, 1)
        })
      } else {
        list.value.splice(index, 1)
      }
      
    }

    const handleSelectChange = (record: any, type: string) => {
      const params = {
        brand: record.brand ? [record.brand] : [],
        typeClass: record.typeClass ? [record.typeClass] : [],
        powerTrain: record.powerTrain ? [record.powerTrain] : [],
      };
      searchVehicle(params).then((res: any) => {
        record.brandOptions = res.brand || ['MBPC', 'AMG', 'VAN'],
        record.typeClassOptions = res.typeClass
        record.powerTrainOptions = res.powerTrain
        record.modelOptions = res.modelName
        record.modelName = res.modelName[0] || ''
        // switch(type) {
        //   case 'brand':
        //     record.typeClass = ''
        //     record.powerTrain = ''
        //     break
        //   case 'typeClass':
        //     record.brand = ''
        //     record.powerTrain = ''
        //     break
        //   case 'powerTrain':
        //     record.brand = ''
        //     record.typeClass = ''
        //     break
        // }
      })
      if (!record.id) {
        const data = {
          brand: record.brand,
          typeClass: record.typeClass,
          powerTrain: record.powerTrain
        }
        keyModelsCreate(data).then(res => {
          console.log(res)
          record.id = res
        })
      } else {
        const data = {
          id: record.id,
          brand: record.brand,
          typeClass: record.typeClass,
          powerTrain: record.powerTrain
        }
        keyModelsEdit(data).then(res => {
          console.log(res)
        })
      }
    }
    
    const init = () => {
      const params = {
        brand: [],
        make: [],
        modelName: [],
        typeClass: [],
        powerTrain: [],
      };
      searchVehicle(params).then((res: any) => {
        allBrandOptions.value = res.brand
        allTypeClassOptions.value = res.typeClass
        allpowerTrainOptions.value = res.powerTrain
        allModelOptions.value = res.modelName
        // list.value.forEach(item => {
        //   item.brandOptions = res.brand || ['MBPC', 'AMG', 'VAN'],
        //   item.typeClassOptions = res.typeClass
        //   item.powerTrainOptions = res.powerTrain
        //   item.modelOptions = res.modelName
        //   item.modelName = res.modelName[0] || ''
        // })
      });

      list.value.forEach(item => {
        searchVehicle({
          brand: item.brand ? [item.brand] : [],
          typeClass: item.typeClass ? [item.typeClass] : [],
          powerTrain: item.powerTrain ? [item.powerTrain] : [],
        }).then((res: any) => {
          item.brandOptions = res.brand || ['MBPC', 'AMG', 'VAN'],
          item.typeClassOptions = res.typeClass
          item.powerTrainOptions = res.powerTrain
          item.modelOptions = res.modelName
          item.modelName = res.modelName[0] || ''
        })
      })
    }
    const getList = async () => {
      const res: any = await keyModelsList()
      list.value = res
      init()
    }
    getList()
    return {
      list,
      handleRemove,
      handleAdd,
      handleSelectChange
    }
  }
})
